<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <create-employee
        v-if="createEmployeeOn"
        @refresh="$refs['employeesList'].refresh()"
        @close="closeCreateModal"
      />
      <div class="d-flex justify-content-end">
        <!-- <b-button
          variant="success"
          class="mr-1"
          @click="createEmployeeOn = true"
        >CREATE
      </b-button> -->
        <b-button
          variant="success"
          class="mr-1"
          @click="openModalCreate()"
        >CREATE
        </b-button>

      </div>
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['employeesList'].refresh()"
      >
        <b-table
          id="employeesList"
          slot="table"
          ref="employeesList"
          show-empty
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="search"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(photo)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <b-avatar
                :src="data.item.url"
                rounded="sm"
              />
            </div>
          </template>
          <template v-slot:cell(employee_number)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <router-link
                target="_blank"
                :class="[textLink]"
                :to="{
                  name: $route.meta.employeeRoute,
                  params: { id: data.item.id },
                }"
              >
                {{ data.item.number }}
              </router-link>
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.pid }}
              </span>
            </div>
          </template>
          <template v-slot:cell(first_name)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.first_name }}
              </span>
            </div>
          </template>
          <template v-slot:cell(last_name)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.last_name }}
              </span>
            </div>
          </template>
          <template v-slot:cell(know_as)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.know_as }}
              </span>
            </div>
          </template>
          <template v-slot:cell(dob)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.dob | myGlobal }}
              </span>
            </div>
          </template>
          <template v-slot:cell(mobile)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.mobile }}
              </span>
            </div>
          </template>
          <template v-slot:cell(department)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.department }}
              </span>
            </div>
          </template>
          <template v-slot:cell(rol)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.rol }}
              </span>
            </div>
          </template>

          <template v-slot:cell(status)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span
                :style="
                  data.item.status === 1
                    ? 'color:#00CC00;font-weight:bold'
                    : 'color:#FF0000;font-weight:bold'
                "
              >
                {{ data.item.status === 1 ? "Active" : "Inactive" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(start_date)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.start_date }}
              </span>
            </div>
          </template>
          <template #cell(corporate_mail_created)="data">
            <span
              :class="{
                'text-primary': data.item.corporate_mail_created === 'PENDING',
                'text-success': data.item.corporate_mail_created === 'COMPLETED',
                'text-danger': data.item.corporate_mail_created === 'REJECTED'
              }"
            >
              {{ data.item.corporate_mail_created }}
            </span>
          </template>
          <template v-slot:cell(created_by)="data">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <span>
                {{ data.item.user_name }}
              </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <div
              v-if="data.item.corporate_mail_created"
            >
              <b-dropdown
                :disabled="data.item.corporate_mail_created === 'COMPLETED' || data.item.corporate_mail_created === 'REJECTED' "
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.corporate_mail_created !== 'PENDING'"
                  v-b-tooltip.hover.left="'Mail creation confirmed'"
                  @click="updateNewEmployees(data, 2)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="text-success"
                  />
                  <span class="pl-1 text-success">COMPLETED</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.corporate_mail_created !== 'REJECTED'"
                  v-b-tooltip.hover.left="'Creation of rejected mail'"
                  @click="updateNewEmployees(data, 3)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="text-danger"
                  />
                  <span class="pl-1 text-danger">REJECTED</span>
                </b-dropdown-item>

              </b-dropdown>
            </div>
            <div>
              <b-button
                v-if="data.item.id_user == null && data.item.status == 1"
                v-b-tooltip.hover
                variant="primary"
                size="sm"
                title="ADD RELATION"
                @click="openModalRelateUser(data.item)"
              >
                <feather-icon icon="UserIcon" /> ADD R.
              </b-button>
              <b-button
                v-else-if="data.item.id_user != null && data.item.status == 1"
                v-b-tooltip.hover
                variant="info"
                size="sm"
                title="RELATED USER"
                @click="openModalRelateUser(data.item)"
              >
                <feather-icon icon="UserIcon" /> RELATE
              </b-button>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <modal-relate-user
      v-if="modalRelateUser"
      :username="username"
      :is-relate="isRelate"
      :employee-id="selectedEmployeeId"
      @hidden="cleanUpEmployee(), (modalRelateUser = false)"
      @on-insert-relation="onInsertRelation"
      @on-delete-relation="onDeleteRelation"
    />
    <modal-create-users
      v-if="openModalCreateUsers"
      @close="closeModalCreate"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot';
import dataFilters from '@/views/management/views/employees/employees-module/employees-list/filters.data';
import EmployeesService from '@/views/management/views/employees/employees.service';
import CreateEmployee from '@/views/management/views/employees/employees-module/employees-list/modals/create-employee/CreateEmployee.vue';
import ModalRelateUser from '@/views/management/views/employees/employees-module/employees-list/modals/relate-user/ModalRelateUser.vue';
import ModalCreateUsers from '@/views/commons/components/charge-back_v2/view/modal/ModalCreateUsers.vue';
import HelpDesk from '@/views/commons/components/helpdesk/services/helpdesk.service';

export default {
  components: {
    FilterSlot,
    CreateEmployee,
    ModalRelateUser,
    ModalCreateUsers,
  },

  data() {
    return {
      openModalCreateUsers: false,
      fields: [
        {
          key: 'photo',
          sortable: false,
          label: 'Photo',
          visible: true,
        },
        {
          key: 'employee_number',
          sortable: false,
          label: 'Employee Number',
          visible: true,
        },
        {
          key: 'id',
          sortable: false,
          label: 'ID',
          visible: true,
        },
        {
          key: 'first_name',
          sortable: false,
          label: 'First Name',
          visible: true,
        },
        {
          key: 'last_name',
          sortable: false,
          label: 'Last Name',
          visible: true,
        },
        {
          key: 'know_as',
          sortable: false,
          label: 'Know as',
          visible: true,
        },
        {
          key: 'dob',
          sortable: false,
          label: 'DOB',
          visible: true,
        },
        {
          key: 'mobile',
          sortable: false,
          label: 'Mobile',
          visible: true,
        },
        {
          key: 'department',
          sortable: false,
          label: 'Department',
          visible: true,
        },

        {
          key: 'rol',
          sortable: false,
          label: 'Rol',
          visible: true,
        },
        {
          key: 'status',
          sortable: false,
          label: 'Status',
          visible: true,
        },
        {
          key: 'start_date',
          sortable: false,
          label: 'Start Date',
          visible: true,
        },
        {
          key: 'corporate_mail_created',
          sortable: false,
          label: 'CORP. MAIL STATUS',
          visible: true,
        },
        {
          key: 'created_by',
          sortable: false,
          label: 'Created By',
          visible: true,
        },
        {
          key: 'actions',
          label: 'Actions',
          visible: true,
          class: 'd-flex',
        },
      ],
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      department: null,
      createEmployeeOn: false,
      modalRelateUser: false,
      isRelate: false,
      username: null,
      selectedEmployeeId: null,
    };
  },

  computed: {
    filteredFields() {
      if (this.moduleId == 17) {
        this.filter[3].visible = true;
      }
      return this.fields.filter(field => field.visible);
    },

    ...mapState({
      S_EMPLOYEE_ID: state => state.ManagementEmployeesStore.S_EMPLOYEE_ID,
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  created() {
    if (this.moduleId == 17) {
      this.fields[this.fields.length - 1].visible = true;
    } else {
      this.fields[this.fields.length - 1].visible = false;
    }
    this.addPreloader();
    this.getDepartments();
    this.removePreloader();
  },

  methods: {
    async updateNewEmployees(data, state) {
      // console.log(data.item);
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const ctx = {
          selectedRowId: data.item.idnew,
          selectedState: state,
        };
        const response = await HelpDesk.updateNewEmployees(ctx);
        if (response.status === 200) {
          this.$refs.employeesList.refresh();
        }

        console.error('Error en la actualización:', response.status, response.data);
      } catch (error) {
        console.log(error);
      }
    },
    openModalCreate() {
      this.openModalCreateUsers = true;
    },
    closeModalCreate() {
      this.openModalCreateUsers = false;
    },
    ...mapMutations({
      setEmplooyeId: 'ManagementEmployeesStore/M_SET_S_EMPLOYEE_ID',
    }),

    closeCreateModal() {
      this.createEmployeeOn = false;
    },
    resetAllFilters() {
      this.filter.forEach(filter => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.employeesList.refresh();
    },
    resetSearch() {
      this.searchInput = '';
      this.$refs.employeesList.refresh();
    },
    openModalRelateUser(item) {
      this.modalRelateUser = true;
      this.isRelate = item.id_user == null;
      this.username = item.id_user == null ? this.username : item.id_user;
      this.selectedEmployeeId = item.id;
    },
    onInsertRelation() {
      if (this.isRelate == false) {
        this.isRelate = true;
      }
      this.modalRelateUser = false;
      this.cleanUpEmployee();
      this.$refs.employeesList.refresh();
    },
    onDeleteRelation() {
      this.modalRelateUser = false;
      this.cleanUpEmployee();
      this.$refs.employeesList.refresh();
    },
    cleanUpEmployee() {
      this.username = null;
      this.selectedEmployeeId = null;
    },
    async search(ctx) {
      try {
        const params = {
          perPage: this.paginate.perPage,

          text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          status: this.moduleId == 16 ? 1 : this.filter[3].model,
          module: this.moduleId,
          departament: this.filter[2].model,
          mailStatus: this.filter[4].model,
        };
        const data = await EmployeesService.getEmployees(
          params,
          ctx.currentPage,
        );

        // eslint-disable-next-line no-param-reassign

        this.items = data.data.data;
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async employeeInformation(item) {
      this.id = item.id;

      await this.setEmplooyeId(this.id);
      await this.$router.push('/management/employees/information');
    },
    async getDepartments() {
      try {
        const params = {};
        const data = await EmployeesService.getAllDepartments(params);

        // eslint-disable-next-line no-param-reassign

        this.department = data.data;
        // Must return an array of items or an empty array if an error occurred

        this.filter[2].options = this.department;
        return this.department;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
</style>
