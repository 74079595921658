export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Department',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status',
    model: null,
    options: [
      { id: 0, name: 'All' },
      { id: 1, name: 'Active ' },
      { id: 2, name: 'Inactive ' },
    ],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: false,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Corp. Mail Status',
    model: null,
    options: [
      { id: 1, name: 'PENDING' },
      { id: 2, name: 'COMPLETED ' },
      { id: 3, name: 'REJECTED ' },
    ],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
  },
];
