<template>
  <b-sidebar
    id="add-new-employer-sidebar"
    v-model="ownControl"
    bg-variant="white"
    sidebar-class="sidebar-xl"
    shadow
    backdrop
    right
    title="Create Employee"
    header-class="text-primary"
    @hidden="closeModal"
  >
    <template #default>
      <validation-observer ref="form">
        <b-form class="p-2">
          <!-- PERSONAL INFORMATION -->
          <template>
            <b-card>
              <template #header>
                <b-card-title>PERSONAL INFORMATION</b-card-title>
              </template>
              <template #default>
                <b-row>
                  <b-col md="3">
                    <validation-provider name="Photo">
                      <b-form-group
                        label="Photo"
                        label-for="photo"
                      >
                        <div
                          class="d-flex justify-content-center align-content-center mb-1"
                        >
                          <b-avatar
                            size="132"
                            square
                            :src="userData.file"
                            width="100"
                          />
                        </div>

                        <b-form-file
                          placeholder="Choose a file..."
                          no-drop
                          accept="image/*"
                          @change="onImageChange"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="9">
                    <b-row>
                      <b-col md="3">
                        <!-- First Name -->
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="First Name"
                          rules="required"
                        >
                          <b-form-group
                            label="First Name (*)"
                            label-for="first-name"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="first-name"
                              v-model="userData.first_name"
                              name="first-name"
                              autofocus
                              :state="errors[0] ? false : valid ? true : null"
                              trim
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- MiddleName -->
                      <b-col md="3">
                        <validation-provider name="MiddleName">
                          <b-form-group
                            label="Middle Name"
                            label-for="middle-name"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="middle-name"
                              v-model="userData.middle_name"
                              trim
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Last Name -->
                      <b-col md="3">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="LastName"
                          rules="required"
                        >
                          <b-form-group
                            label="Last Name (*)"
                            label-for="last-name"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="last-name"
                              v-model="userData.last_name"
                              trim
                              :state="errors[0] ? false : valid ? true : null"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- KnowAs -->
                      <b-col md="3">
                        <validation-provider name="KnowAs">
                          <b-form-group
                            label="Know as"
                            label-for="know-as"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="know-as"
                              v-model="userData.know"
                              trim
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- Doc Type-->

                      <b-col md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="DocType"
                          rules="required"
                        >
                          <b-form-group
                            label="Doc Type (*)"
                            label-for="doc-type"
                            :state="errors[0] ? false : null"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <v-select
                              v-if="finishedLoading"
                              v-model="userData.dtype"
                              label="name"
                              :reduce="(val) => val.id"
                              :options="document"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- MiddleName -->
                      <b-col md="3">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="NumberDoc"
                          rules="required"
                        >
                          <b-form-group
                            label="Number Doc (*)"
                            label-for="number-doc"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="number-doc"
                              v-model="userData.dnumber"
                              trim
                              :state="errors[0] ? false : valid ? true : null"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- DOE -->
                      <b-col md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="DOE"
                          rules="required"
                        >
                          <b-form-group
                            label="DOE (*)"
                            label-for="doe"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <kendo-datepicker
                              v-if="finishedLoading"
                              v-model="userData.doe"
                              v-mask="'##/##/####'"
                              :format="'MM/dd/yyyy'"
                              :state="errors[0] ? false : null"
                              :class="errors[0] ? 'border-danger rounded' : ''"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Nationality -->
                      <b-col md="3">
                        <validation-provider name="Nationality">
                          <b-form-group
                            label="Nationality"
                            label-for="nationality"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <b-form-input
                              v-if="finishedLoading"
                              id="know-as"
                              v-model="userData.nationality"
                              trim
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- DOB -->
                    <b-row>
                      <b-col md="3">
                        <validation-provider name="DOB">
                          <b-form-group
                            label="DOB"
                            label-for="dob"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />

                            <kendo-datepicker
                              v-if="finishedLoading"
                              v-model="userData.dob"
                              v-mask="'##/##/####'"
                              :class="[
                                'w-auto rounded bg-transparent k-picker-custom  picker-select-date',
                                { 'text-white': isDarkSkin },
                                'custom-caro22',
                              ]"
                              :format="'MM/dd/yyyy'"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <!-- Doc Type-->
                        <validation-provider
                          v-slot="{ errors }"
                          name="Gender"
                          rules="required"
                        >
                          <b-form-group
                            label="Gender (*)"
                            label-for="gender"
                            :state="errors[0] ? false : null"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <v-select
                              v-if="finishedLoading"
                              v-model="userData.gender"
                              label="gender"
                              :reduce="(val) => val.id"
                              :options="gender"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <!-- Doc Type-->
                        <validation-provider name="Martial ">
                          <b-form-group
                            label="Martial"
                            label-for="martial"
                          >
                            <b-skeleton
                              v-if="!finishedLoading"
                              type="input"
                            />
                            <v-select
                              v-if="finishedLoading"
                              v-model="userData.martial"
                              label="name"
                              :reduce="(val) => val.id"
                              :options="martial"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>

          <!-- CONTACT INFORMATION -->
          <template>
            <b-card>
              <template #header>
                <b-card-title>CONTACT INFORMATION</b-card-title>
              </template>
              <template #default>
                <b-row>
                  <b-col md="3">
                    <!-- First  Name -->
                    <validation-provider name="MailingAddress">
                      <b-form-group
                        label="Mailing Address"
                        label-for="mailing-address"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="mailing-address"
                          v-model="userData.address"
                          name="mailing-address"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- MiddleName -->
                  <b-col md="3">
                    <validation-provider name="City">
                      <b-form-group
                        label="City"
                        label-for="city"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="city"
                          v-model="userData.city"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- LastName -->
                  <b-col md="3">
                    <validation-provider name="State">
                      <b-form-group
                        label="State"
                        label-for="state"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="tate"
                          v-model="userData.state"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <!-- First Name -->
                    <validation-provider name="Zip Code">
                      <b-form-group
                        label="Zip Code"
                        label-for="zip-code"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="zip-code"
                          v-model="userData.zip"
                          name="zip-code"
                          type="number"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- MiddleName -->
                  <b-col md="3">
                    <validation-provider name="Country">
                      <b-form-group
                        label="Country"
                        label-for="country"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="country"
                          v-model="userData.country"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- KnowAs -->
                  <b-col md="3">
                    <validation-provider name="HomePhone">
                      <b-form-group
                        label="Home Phone"
                        label-for="home-phone"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="home-phone"
                          v-model="userData.phone"
                          type="number"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Doc Type-->

                  <b-col md="3">
                    <!-- First Name -->
                    <validation-provider name="Mobile Phone">
                      <b-form-group
                        label="Mobile Phone"
                        label-for="mobile-phone"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="mobile-phone"
                          v-model="userData.pmobile"
                          v-mask="'###-###-###'"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- MiddleName -->
                  <b-col md="3">
                    <validation-provider name="Work Phone">
                      <b-form-group
                        label="Work Phone"
                        label-for="work-phone"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="work-phone"
                          v-model="userData.pwork"
                          v-mask="'(XXX)-XXX-XXXX'"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- LastName -->
                  <b-col md="3">
                    <validation-provider name="WorkEmail">
                      <b-form-group
                        label="Work Email"
                        label-for="work-email"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="work-email"
                          v-model="userData.ework"
                          type="email"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- KnowAs -->
                  <b-col md="3">
                    <validation-provider name="PrivateEmail">
                      <b-form-group
                        label="Private Email"
                        label-for="private-email"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="private-email"
                          v-model="userData.eprivate"
                          type="email"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>
          <!--EMERGENCY CONTACT -->
          <template>
            <b-card>
              <template #header>
                <b-card-title>EMERGENCY CONTACT</b-card-title>
              </template>
              <template #default>
                <b-row>
                  <b-col md="4">
                    <!-- First  Name -->
                    <validation-provider name="ContactName">
                      <b-form-group
                        label="Contact Name"
                        label-for="contact-name1"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="contact-name1"
                          v-model="userData.cname1"
                          name="contact-name1"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- MiddleName -->
                  <b-col md="4">
                    <validation-provider name="Contact Phone">
                      <b-form-group
                        label="Contact Phone"
                        label-for="contact-phone1"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="contact-phone1"
                          v-model="userData.cphone1"
                          type="number"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- LastName -->
                  <b-col md="4">
                    <!-- Doc Type-->
                    <validation-provider name="Relation">
                      <b-form-group
                        label="Relation"
                        label-for="relation1"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.crelation1"
                          label="name"
                          :reduce="(val) => val.id"
                          :options="relations"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="4">
                    <!-- First  Name -->
                    <validation-provider name="ContactName">
                      <b-form-group
                        label="Contact Name"
                        label-for="contact-name2"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="contact-name1"
                          v-model="userData.cname2"
                          name="contact-name1"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- MiddleName -->
                  <b-col md="4">
                    <validation-provider name="Contact Phone">
                      <b-form-group
                        label="Contact Phone"
                        label-for="contact-phone2"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="contact-phone2"
                          v-model="userData.cphone2"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- LastName -->
                  <b-col md="4">
                    <!-- Doc Type-->
                    <validation-provider name="Relation2">
                      <b-form-group
                        label="Relation"
                        label-for="relation2"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.crelation2"
                          label="name"
                          :reduce="(val) => val.id"
                          :options="relations"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>

          <!--JOB DETAILS -->
          <template>
            <b-card>
              <template #header>
                <b-card-title>JOB DETAILS</b-card-title>
              </template>
              <template #default>
                <b-row>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Start Date"
                      rules="required"
                    >
                      <b-form-group
                        label="Start Date (*)"
                        label-for="start-date"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <kendo-datepicker
                          v-if="finishedLoading"
                          v-model="userData.start_date"
                          v-mask="'##/##/####'"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :format="'MM/dd/yyyy'"
                          :state="errors[0] ? false : null"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <validation-provider name="End Date">
                      <b-form-group
                        label="End Date"
                        label-for="end-date"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-datepicker
                          v-if="finishedLoading"
                          v-model="userData.end_date"
                          locale="en"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <!-- Doc Type-->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Department"
                      rules="required"
                    >
                      <b-form-group
                        label="Department (*)"
                        label-for="Department"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.department"
                          label="name"
                          :reduce="(val) => val.id"
                          :calculate-position="withPopper"
                          :options="department"
                          @input="getRolByDepartment"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3">
                    <!-- Doc Type-->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Rol"
                      rules="required"
                    >
                      <b-form-group
                        label="Rol (*)"
                        label-for="Rol"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.role"
                          label="name"
                          :reduce="(val) => val.id"
                          :calculate-position="withPopper"
                          :options="optionsRol"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="6">
                    <!-- Company -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Company"
                      rules="required"
                    >
                      <b-form-group
                        label="Company (*)"
                        label-for="Company"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"/>

                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.company"
                          label="business_name"
                          :reduce="(val) => val.id"
                          :calculate-position="withPopper"
                          :options="company"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Has Family Allowance -->
                  <b-col v-if="companyWithFamilyAllowance(userData.company)"
                  class="d-flex align-items-center justify content-start"
                  >
                    <validation-provider>
                      <b-skeleton
                        v-if="!finishedLoading"
                        class="space flex-grow-1"
                        type="button"
                      />
                      <b-form-checkbox
                        id="checkbox-2"
                        v-model="userData.has_family_allowance"
                        name="checkbox-2"
                        value="1"

                        unchecked-value="0"
                      >
                        Has Family Allowance?
                      </b-form-checkbox>
                    </validation-provider>
                  </b-col>

                  <b-col md="3">
                    <!-- Pension Fund -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Pension Fund"
                      rules="required"
                    >
                      <b-form-group
                        label="Pension Fund (*)"
                        label-for="Pension Fund"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.pfund"
                          label="name"
                          :reduce="(val) => val.id"
                          :calculate-position="withPopper"
                          :options="pfund"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Start Date Sunat"
                      rules="required"
                    >
                      <b-form-group
                        label="Start Date (*) Sunat"
                        label-for="start-date-sunat"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <kendo-datepicker
                          v-if="finishedLoading"
                          v-model="userData.start_date_sunat"
                          v-mask="'##/##/####'"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :format="'MM/dd/yyyy'"
                          :state="errors[0] ? false : null"
                        />
                      </b-form-group>
                    </validation-provider>

                  </b-col>
                  <b-col md="3">
                    <!-- First Name -->
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="CUSPP"
                      rules="required"
                    >
                      <b-form-group
                        label="CUSPP"
                        label-for="cuspp"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="cuspp"
                          v-model="userData.cuspp"
                          name="cuspp"
                          autofocus
                          :state="errors[0] ? false : valid ? true : null"
                          trim
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>

          <!-- Payment Information -->
          <template>
            <b-card>
              <template #header>
                <b-card-title>Payment Information</b-card-title>
              </template>
              <template #default>
                <b-row>
                  <b-col md="3">
                    <!-- Payment Method -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Payment Method"
                      rules="required"
                    >
                      <b-form-group
                        label="Payment Method"
                        label-for="Payment Method"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.method"
                          :reduce="(val) => val.method"
                          label="name"
                          :options="optionsMethod"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    v-if="userData.method === 1"
                    md="3"
                  >
                    <!-- Transfer Prefered -->
                    <validation-provider
                      ref="preferedCurrencyForm"
                      v-slot="{ errors }"
                      name="Prefered Transfer"
                      rules="required"
                    >
                      <b-form-group label="Select a transfer type">
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-select
                          v-if="finishedLoading"
                          v-model="userData.prefered_transfer"
                          value-field="method"
                          text-field="name"
                          :options="optionsTransfer"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="userData.method === 1"
                    md="3"
                  >
                    <!-- Currency Prefered -->
                    <validation-provider
                      ref="preferedCurrencyForm"
                      v-slot="{ errors }"
                      name="Prefered Currency"
                      rules="required"
                    >
                      <b-form-group label="Select a currency">
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-select
                          v-if="finishedLoading"
                          v-model="userData.prefered_currency"
                          value-field="currency"
                          text-field="name"
                          :options="optionsCurrency"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="userData.method === 1"
                    md="12"
                  >
                    <b-button
                      variant="success"
                      size="sm"
                      class="mb-1 btn-sm float-right"
                      @click="openFormAddBank"
                    >ADD NEW BANK
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="userData.method === 1"
                    md="12"
                    class="mt-1"
                  >
                    <h4>ACCOUNT INFORMATION IN SOLES (S/)</h4>
                  </b-col>
                  <b-col
                    v-if="userData.method === 1"
                    md="6"
                  >
                    <!-- Bank Name Soles -->

                    <validation-provider
                      v-slot="{ errors }"
                      name="Bank Name Soles"
                      rules="required"
                    >
                      <b-form-group
                        label="Bank Name Soles (*)"
                        label-for="Bank Nmae Soles"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.bank_name_soles"
                          label="name"
                          :reduce="(val) => val.id"
                          :calculate-position="withPopper"
                          :options="bank_name"
                          @change="onOptionChangeSol"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="showFormBankSoles && userData.method === 1"
                    md="6"
                  >
                    <!-- Account Type Soles-->

                    <validation-provider
                      v-slot="{ errors }"
                      name="Account Type Soles"
                      rules="required"
                    >
                      <b-form-group
                        label="Account Type Soles (*)"
                        label-for="Account Type Soles"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.account_type_soles"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          value-field="method"
                          text-field="name"
                          :reduce="(val) => val.method"
                          :options="optionsAccounType"
                          label="name"
                          :calculate-position="withPopper"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="showFormBankSoles && userData.method === 1"
                    md="6"
                  >
                    <!-- Account Number Soles -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Account Number Soles"
                      rules="required|valid-number-account"
                    >
                      <b-form-group
                        label="Account Number Soles (*)"
                        label-for="Account Number Soles"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="Account Number Soles"
                          v-model="userData.account_number_soles"
                          v-mask="'######################'"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          trim
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="showFormBankSoles && userData.method === 1"
                    md="6"
                  >
                    <!-- CCI Soles -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="CCI Soles"
                      rules="required|valid-cci-code"
                    >
                      <b-form-group
                        label="CCI Soles (*)"
                        label-for="CCI Soles"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="CCI Soles"
                          v-model="userData.account_cci_soles"
                          v-mask="'############################'"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          trim
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="showFormBankSoles && userData.method === 1"
                    md="2"
                    class="mt-1"
                  >
                    <b-button
                      variant="outline-danger"
                      @click="hideFormSol"
                    >
                      Remove Form
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    v-if="userData.method === 1"
                    md="12"
                    class="mt-1"
                  >
                    <h4>ACCOUNT INFORMATION IN DOLLARS ($)</h4>
                  </b-col>
                  <b-col
                    v-if="userData.method === 1"
                    md="6"
                  >
                    <!-- Bank Name Dollars-->

                    <validation-provider
                      v-slot="{ errors }"
                      name="Bank Name Dollars"

                      :rules="isBankNameDollars ? required : ''"
                    >

                      <b-form-group
                        label="Bank Name Dollars (*)"
                        label-for="Bank Name Dollars"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.bank_name_dollars"
                          label="name"
                          :reduce="(val) => val.id"
                          :calculate-position="withPopper"
                          :options="bank_name"
                          @change="onOptionChangeDollar"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="showFormBankDollars && userData.method === 1"
                    md="6"
                  >
                    <!-- Account Type Dollars -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Account Type Dollars"
                      rules="required"
                    >
                      <b-form-group
                        label="Account Type Dollars (*)"
                        label-for="Account Type Dollars"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <v-select
                          v-if="finishedLoading"
                          v-model="userData.account_type_dollars"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          :reduce="(val) => val.method"
                          value-field="method"
                          label="name"
                          text-field="name"
                          :calculate-position="withPopper"
                          :options="optionsAccounType"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="showFormBankDollars && userData.method === 1"
                    md="6"
                  >
                    <!-- Account Number Dollars -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="Account Number Dollars"
                      rules="required|valid-number-account"
                    >
                      <b-form-group
                        label="Account Number Dollars (*)"
                        label-for="Account Number Dollars"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="Account Number Dollars"
                          v-model="userData.account_number_dollars"
                          v-mask="'##############'"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          trim
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    v-if="showFormBankDollars && userData.method === 1"
                    md="6"
                  >
                    <!-- CCI Dollars -->
                    <validation-provider
                      v-slot="{ errors }"
                      name="CCI Dollars"
                      rules="required|valid-cci-code"
                    >
                      <b-form-group
                        label="CCI Dollars (*)"
                        label-for="CCI Dollars"
                        :state="errors[0] ? false : null"
                      >
                        <b-skeleton
                          v-if="!finishedLoading"
                          type="input"
                        />
                        <b-form-input
                          v-if="finishedLoading"
                          id="CCI Dollars"
                          v-model="userData.account_cci_dollars"
                          v-mask="'####################'"
                          :class="errors[0] ? 'border-danger rounded' : ''"
                          trim
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="showFormBankDollars && userData.method === 1"
                    md="2"
                    class="mt-1"
                  >
                    <b-button
                      variant="outline-danger"
                      @click="hideFormDollar"
                    >
                      Remove Form
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </template>
        </b-form>
      </validation-observer>
      <add-new-bank
        v-if="openAddNewModal"
        @close="closeModalAddBank"
        @saved="getAllBanks"
      />
    </template>

    <template #footer>
      <b-row class="px-3 py-2">
        <b-col lg="6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="info"
            @click="resetForm"
          >RESET
          </b-button>
        </b-col>
        <b-col lg="6">
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="createEmployee"
            >
              <b-spinner
                v-if="spinnerOn"
                small
              />
              <span> SAVE</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="closeModal"
            >CANCEL
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { mapGetters } from 'vuex';
import { createPopper } from '@popperjs/core';
import EmployeesService from '@/views/management/views/employees/employees.service';
import AddNewBank from '@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/modals/AddNewBank/AddNewBank.vue';

export default {
  components: {
    vSelect,
    AddNewBank,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showFormBankSoles: false,
      showFormBankDollars: false,

      optionsTransfer: [
        { method: 1, name: 'Direct' },
        { method: 2, name: 'Interbank' },
      ],

      openAddNewModal: false,
      bankname: '',
      showAddBank: false,

      gender: [
        { id: 1, gender: 'Female' },
        { id: 2, gender: 'Male' },
      ],
      ownControl: false,
      userData: {
        file: '',
        method: null,
        prefered_transfer: 1,
        prefered_currency: 2,
      },
      configFlatPickr: {
        dateFormat: 'd/m/Y',
        locale: 'en',
      },
      optionsMethod: [
        { method: 1, name: 'Transfer' },
        { method: 2, name: 'Deposit' },
        { method: 3, name: 'Cash' },
        { method: 4, name: 'Check' },
      ],

      optionsAccounType: [
        { method: 1, name: 'Current' },
        { method: 2, name: 'Master' },
        { method: 3, name: 'Savings' },
      ],

      optionsCurrency: [
        { currency: 1, name: '$ (Dollar)' },
        { currency: 2, name: 'S./ (Soles)' },
      ],

      bankAccounts: null,

      relations: [],
      department: [],
      company: [],
      pfund: [],
      bank_name: [],
      optionsPensionFund: [],
      martial: [],
      document: [],
      image: null,
      optionsRol: [],
      spinnerOn: false,
      finishedLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isBankNameDollars() {
      return !!this.userData.bank_name_dollars;
    },
    isBankNameSoles() {
      return !!this.userData.bank_name_soles;
    },
  },
  watch: {
    isBankNameDollars(newValue, oldValue) {
      if (newValue) {
        this.showFormBankDollars = true;
      }
    },

    isBankNameSoles(newValue, oldValue) {
      if (newValue) {
        this.showFormBankSoles = true;
      }
    },
  },

  async mounted() {
    this.ownControl = true;

    await Promise.all([
      this.getAllDocuments(),
      this.getAllMartial(),
      this.getAllRelations(),
      this.getAllDepartments(),
      this.getAllCompanies(),
      this.getAllPensionFund(),
      this.getAllBanks(),
    ]);

    this.finishedLoading = true;
  },

  methods: {

    companyWithFamilyAllowance(id) {
      const selectedCompany = this.company.find(item => item.id === id);
      if (selectedCompany) {
        return selectedCompany.family_allowance === 1;
      }
      return false;
    },

    onOptionChangeSol() {
      if (this.userData.bank_name_soles) {
        this.showFormBankSoles = true;
      } else {
        this.showFormBankSoles = false;
      }
    },
    hideFormSol() {
      this.userData.bank_name_soles = null;
      this.userData.account_type_soles = null;
      this.userData.account_number_soles = null;
      this.userData.account_cci_soles = null; // Para deseleccionar la opción
      this.showFormBankSoles = false;
    },

    onOptionChangeDollar() {
      if (this.userData.bank_name_dollars) {
        this.showFormBankDollars = true;
      } else {
        this.showFormBankDollars = false;
      }
    },
    hideFormDollar() {
      this.userData.bank_name_dollars = null;
      this.userData.account_type_dollars = null;
      this.userData.account_number_dollars = null;
      this.userData.account_cci_dollars = null; // Para deseleccionar la opción
      this.showFormBankDollars = false;
    },

    async postBank() {
      const data = {
        bank_name: this.bankname,
      };

      try {
        const response = await EmployeesService.postBank(data);
        if (response.status === 200) {
          this.closeModalAddBank();
          this.showAddBank = false;
          this.bankname = null;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
      return [];
    },

    onImageChange(e) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = f => {
        this.userData.image = f.target.result;
      };
      const filearch = e.target.files[0];

      const file = e.target.files[0].name;

      const { size } = e.target.files[0];
      this.userData.namedoc = file;
      this.userData.size = size;
      this.userData.file = URL.createObjectURL(filearch);
    },
    closeModal() {
      this.$emit('close');
    },
    async createEmployee() {
      try {
        switch (this.userData.method) {
          case 1:
            this.bankAccounts = this.getAllBankAccounts();
            break;

          case 2:
            this.bankAccounts = [
              {
                // Check Method
                payment_type: this.userData.method,
                subtype: 'Direct',
                currency: 'PEN',
                account_number: '',
                bank_account_id: 1,
                account_type: 'Current',
              },
            ];
            break;
          case 3:
            this.bankAccounts = [
              {
                // Deposit Method
                payment_type: this.userData.method,
                subtype: 'Direct',
                currency: 'PEN',
                account_number: '',
                bank_account_id: 1,
                account_type: 'Current',
              },
            ];
            break;
          case 4:
            this.bankAccounts = [
              {
                // Cash Method
                payment_type: this.userData.method,
                subtype: 'Direct',
                currency: 'PEN',
                account_number: '',
                bank_account_id: 1,
                account_type: 'Current',
              },
            ];
            break;
          default:
            this.bankAccounts = [];
            break;
        }

        this.spinnerOn = true;
        const result = await this.$refs.form.validate();

        if (!result) {
          this.$refs.form.validate().then(success => {
            if (!success) {
              setTimeout(() => {
                this.spinnerOn = false;
                const errors = Object.entries(this.$refs.form.errors)
                  .map(([key, value]) => ({ key, value }))
                  .filter(error => error.value.length);
                this.$refs.form.refs[errors[0].key].$el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }, 100);
            }
          });
          return;
        }

        this.spinnerOn = false;
        if (!result) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        if (result) {
          const params = {
            first_name: this.userData.first_name,
            middle_name: this.userData.middle_name,
            last_name: this.userData.last_name,
            know: this.userData.know,
            dtype: this.userData.dtype,
            dnumber: this.userData.dnumber,
            nationality: this.userData.nationality,
            dob: this.userData.dob,
            gender: this.userData.gender,
            martial: this.userData.martial,
            address: this.userData.address,
            city: this.userData.city,
            state: this.userData.state,
            zip: this.userData.zip,
            country: this.userData.country,
            phone: this.userData.phone,
            pmobile: this.userData.pmobile,
            pwork: this.userData.pwork,
            ework: this.userData.ework,
            eprivate: this.userData.eprivate,
            cname1: this.userData.cname1,
            cphone1: this.userData.cphone1,
            crelation1: this.userData.crelation1,
            cname2: this.userData.cname2,
            cphone2: this.userData.cphone2,
            crelation2: this.userData.crelation2,
            start_date: this.userData.start_date,
            end_date: this.userData.end_date,
            departament: this.userData.department,
            role: this.userData.role,
            namedoc: this.userData.namedoc,
            size: this.userData.size,
            image: this.userData.image,
            user_id: this.currentUser.user_id,
            doe: this.userData.doe,

            company: this.userData.company,
            has_family_allowence: parseInt(this.userData.has_family_allowance, 10),
            pfund: this.userData.pfund,
            start_date_sunat: this.userData.start_date_sunat,
            cuspp: this.userData.cuspp,

            arrayBankAccounts: this.bankAccounts,
          };
          this.addPreloader();
          const data = await EmployeesService.createEmployee(params);
          if (data.status === 200) {
            if (data.data[0].ERROR === 1) {
              this.showWarningSwal('The Number Doc Already exists!');
            } else {
              this.$emit('refresh');
              this.spinnerOn = false;
              this.showSuccessSwal();
              this.closeModal();
            }
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
      return [];
    },

    resetForm() {
      this.userData = { file: '' };
    },
    async getAllRelations() {
      try {
        const data = await EmployeesService.getAllRelations();

        // eslint-disable-next-line no-param-reassign

        this.relations = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.relations;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    openFormAddBank() {
      this.openAddNewModal = true;
    },

    closeModalAddBank() {
      this.openAddNewModal = false;
      this.bankname = null;
    },

    getAllBankAccounts() {
      const data = [
        {
          payment_type:
            this.userData.method !== null ? this.userData.method : '',
          subtype: 'Direct',
          currency: 'PEN',
          account_number:
            this.userData.account_number_soles !== null
              ? this.userData.account_number_soles
              : '',
          bank_account_id:
            this.userData.bank_name_soles !== null
              ? this.userData.bank_name_soles
              : '',
          account_type:
            this.userData.account_type_soles !== null
              ? this.userData.account_type_soles
              : '',
          preferred:
            this.userData.prefered_currency === 2 && this.userData.prefered_transfer === 1 ? 1 : 0,
        },
        {
          payment_type:
            this.userData.method !== null ? this.userData.method : '',
          subtype: 'Interbank',
          currency: 'PEN',
          account_number:
            this.userData.account_cci_soles !== null
              ? this.userData.account_cci_soles
              : '',
          bank_account_id:
            this.userData.bank_name_soles !== null
              ? this.userData.bank_name_soles
              : '',
          account_type:
            this.userData.account_type_soles !== null
              ? this.userData.account_type_soles
              : '',
          preferred:
            this.userData.prefered_currency === 2 && this.userData.prefered_transfer === 2 ? 1 : 0,
        },
        {
          payment_type:
            this.userData.method !== null ? this.userData.method : '',
          subtype: 'Direct',
          currency: 'USD',
          account_number: this.userData.account_number_dollars,
          bank_account_id: this.userData.bank_name_dollars,
          account_type: this.userData.account_type_dollars,
          preferred: this.userData.prefered_currency === 1 && this.userData.prefered_transfer === 1 ? 1 : 0,
        },
        {
          payment_type:
            this.userData.method !== null ? this.userData.method : '',
          subtype: 'Interbank',
          currency: 'USD',
          account_number: this.userData.account_cci_dollars,
          bank_account_id: this.userData.bank_name_dollars,
          account_type: this.userData.account_type_dollars,
          preferred: this.userData.prefered_currency === 1 && this.userData.prefered_transfer === 2 ? 1 : 0,
        },
      ];
      return data;
    },

    async getAllDepartments() {
      try {
        const data = await EmployeesService.getAllDepartments();

        // eslint-disable-next-line no-param-reassign

        this.department = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.department;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllCompanies() {
      try {
        const params = {
          campo: null,
          orderby: 1,
          order: 'asc',
          perpage: 10,
          page: 1,
        };

        const data = await EmployeesService.getAllCompanies(params);

        // eslint-disable-next-line no-param-reassign

        this.company = data.data;

        // Must return an array of items or an empty array if an error occurred

        return this.company;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllPensionFund() {
      try {
        const data = await EmployeesService.getAllPensionFund();

        // eslint-disable-next-line no-param-reassign

        this.pfund = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.pfund;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllBanks() {
      try {
        const data = await EmployeesService.getAllBanks();

        // eslint-disable-next-line no-param-reassign

        this.bank_name = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.bank_name;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getAllDocuments() {
      try {
        const data = await EmployeesService.getAllDocuments();

        // eslint-disable-next-line no-param-reassign

        this.document = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.document;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllMartial() {
      try {
        const data = await EmployeesService.getAllMartial();

        // eslint-disable-next-line no-param-reassign

        this.martial = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.martial;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getRolByDepartment() {
      try {
        const params = {
          id: this.userData.department,
        };
        const data = await EmployeesService.getRolByDepartment(params);

        // eslint-disable-next-line no-param-reassign

        this.optionsRol = data.data;
        // Must return an array of items or an empty array if an error occurred

        this.userData.role = null;

        return this.optionsRol;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    withPopper(dropdownList, component, { width }) {
      const dropdownListCopy = { ...dropdownList };
      dropdownListCopy.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownListCopy, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

#overpx .vs__dropdown-toggle .vs__selected-options .vs__search {
  margin: 9.15px 0 0 !important;
}

#add-new-employer-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl {
  width: 90rem !important;
}

.b-sidebar-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;

  .close {
    margin-right: revert !important;
  }
}

.custom-caro.k-input {
  max-width: 218px !important;
  width: 300px !important;
}

.custom-caro22.k-input {
  max-width: 157px !important;
  width: 300px !important;
}
</style>
