<template>
  <b-modal
    v-model="onControl"
    modal-class="modal-primary"
    :title="`Create user and employee`"
    title-tag="h3"
    size="xl"
    centered
    no-close-on-backdrop
    header-bg-variant="primary"
    @hidden="close"
  >
    <b-alert
      variant="danger"
      :show="currentUser === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list' }"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <template v-if="currentUser">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-info-card
            ref="cardComponent"
            :user-data="userData"
            :form="form"
            :modules="modules"
            :programs-options="programsOptions"
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col
          cols="12"
          lg="6"
        >
          <user-view-access-card :user-data="currentUser" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-session-list-card />
        </b-col> -->
      </b-row>
    </template>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="md"
          class="float-right mr-1"
          @click="updateUser()"
        >
          CREATE
        </b-button>
        <b-button
          variant="secondary"
          size="md"
          class="float-right mr-1"
          @click="resetForm"
        >
          RESET
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EditService from '@/views/users/commons/users/services/edit-user.service';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';
import UserViewInfoCard from '@/views/users/commons/users/user-view/UserViewInfoCard.vue';

export default {
  components: {
    UserViewInfoCard,
  },
  data() {
    return {
      idsUserModule: [],
      programsOptions: [],
      modules: [],
      userData: {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        country: 'PE',
        password: '',
        phone: '',
        cellphone: '',
        date_of_birth: moment().format('YYYY-MM-DD'),
        restriction_by_ip: false,
        language: 0,
        skill: { id: 0, name: 'Select a skill' },
        status: 1,
        module: null,
        role: null,
        typesenior: '',
        rolenew: null,
        file: '',
        image: '',
        admin: null,
        creditExpertAdmin: 1,
        others: '0',
        super: '',
        superid: '',
        extension: '',
        social_network: null,
        newarr: [],
        program: [],
        mainmodule: null,

        gender: null,
        dtype: null,
        dnumber: null,
        start_date: moment().format('YYYY-MM-DD'),
        department_id: null,
        rol_id: null,

      },
      form: {
        file: '',
        image: '',
        remove: false,
      },
      onControl: false,
    };
  },
  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
  },
  async created() {
    this.onControl = true;
    this.addPreloader();
    if (this.$route.params.id) {
      await this.getInfoUser();
    }
    this.userData.superid = this.currentUser.user_id;
    this.userData.super = this.currentUser.role_id;
    await Promise.all([this.getAllPrograms(), this.getAllModules()]);
    this.removePreloader();
  },

  mounted() {

  },
  methods: {
    async getInfoUser() {
      try {
        const { data } = await EditService.getUserEdit({
          id: this.$route.params.id,
        });
        this.idsUserModule = [];
        this.userData = { ...data[0] };
        this.$set(this.userData, 'admin', this.userData.admin_type);
        this.$set(this.userData, 'checkEmail', this.userData.email);
        this.$set(this.userData, 'skill', {
          id: this.userData.lang,
          name: 'Select a skill',
        });
        // this.userData.skill.id = this.userData.lang
        this.form.image = this.userData.image;
        const arrPermission = this.userData.sale_permission
          ? this.userData.sale_permission
          : [];
        this.$set(this.userData, 'program', arrPermission);
        let module = this.userData.newarr.find(
          ({ main_module }) => main_module === 1,
        );
        module = module ? module.id_module : null;
        this.$set(this.userData, 'mainmodule', module);

        // get ids

        // get module_id
      } catch (e) {
        this.removePreloader();
        throw e;
      }
    },
    async getAllPrograms() {
      try {
        this.programsOptions = await EditService.getAllPrograms();
      } catch (e) {
        this.removePreloader();
        throw e;
      }
    },
    async getAllModules() {
      try {
        const data = await helpdeskService.getModules();
        this.modules = data.data;
      } catch (e) {
        this.removePreloader();
        throw e;
      }
    },
    ...mapActions({
      A_UPDATE_PROFILE: 'ProfileStore/A_UPDATE_PROFILE',
      updateCurrentUserInformation: 'auth/updateCurrentUserInformation',
    }),
    close() {
      this.$emit('close');
    },
    resetForm() {
      this.userData = { file: '' };
    },

    async updateUser() {
      console.log(this.userData, 'adatas');
      try {
        const refForm = this.$refs.cardComponent;
        const answer = await refForm.$refs.form.validate();
        let isAnElement = true;
        let isMain = false;
        this.userData.language = this.userData.skill.id;
        if (this.userData.admin === 2) {
          // if (!this.userData.newarr.find((item) => item.parent_id !== 21)) {

          // return ids of subModules if parent_id is 21
          const subModules = this.modules.filter(item => item.parent_id === 21).map(item => item.id);
          if (subModules.includes(this.userData.mainmodule)) {
            this.userData.mainmodule = 6;
            this.uniqueModuleCedChild = true;
          }
          // }
          if (this.userData.newarr.length > 1) {
            const index = this.userData.newarr.findIndex(
              element => element.id_module === 21,
            );
            this.deletedItem = this.userData.newarr[index];
            this.wasDeleted = true;
            if (this.userData.unique_module_ced_module === 1) {
              this.userData.newarr.splice(index, 1);
            }
          }
          if (this.userData.newarr.length <= 0) {
            isAnElement = false;
            isMain = true;
          } else if (this.userData.newarr.length === 1) {
            this.userData.mainmodule = this.userData.newarr[0].id_module;
            // if (!this.uniqueModuleCedChild) {
            //   this.userData.mainmodule = 6;
            // }
          } else if (
            !this.userData.newarr.find(
              item => item.id_module === this.userData.mainmodule,
            )
          ) {
            isMain = true;
            if (this.userData.mainmodule === 21) {
              isMain = false;
            }
          }
        }

        if (this.userData.mainmodule === 21) {
          this.userData.mainmodule = 6;
        }
        if (
          answer
          && !refForm.isValidEmail
          && (this.userData.admin === 1
            || (this.userData.admin === 2 && this.userData.mainmodule !== null))
          && isAnElement
          && !isMain
        ) {
          const result = await this.showConfirmSwal('Are you sure?');
          if (result.value) {
            if (this.containsChiefCreditExpertsDigital) {
              this.modules.forEach(item => {
                if (item.parent_id === 21) {
                  this.userData.newarr.push({
                    id_module: item.id,
                    module: item.name,
                    parent_id: item.parent_id,
                    id_role: 17,
                    typesenior: 0,
                  });
                }
              });
              if (!this.containsCreditExpertsRegular) {
                this.userData.newarr.push({
                  id_module: 6, // credit experts regular
                  module: 'CREDIT EXPERTS REGULAR',
                  parent_id: null,
                  id_role: 3,
                  typesenior: 0,
                });
                // this.userData.mainmodule=6
              }
            } else if (this.containsAChildOfCreditExpertsDigital) {
              this.userData.newarr.push({
                id_module: 21, // credit experts digital
                module: 'CREDIT EXPERTS DIGITAL',
                parent_id: null,
                id_role: 15,
                typesenior: 0,
              });
              if (!this.containsCreditExpertsRegular) {
                this.userData.newarr.push({
                  id_module: 6, // credit experts regular
                  module: 'CREDIT EXPERTS REGULAR',
                  parent_id: null,
                  id_role: 3,
                  typesenior: 0,

                });
                this.userData.mainmodule = 6;
              }
            }
            this.userData.newarr.forEach(item => {
              this.idsUserModule.push(item.id_module);
            });

            this.$set(this.userData, 'idsUserModule', this.idsUserModule.join(','));

            this.addPreloader();

            const data = this.$route.params.id
              ? await EditService.userUpdate(refForm.userData)
              : await EditService.userCreate(refForm.userData);
            if (data.status === 206) {
              this.showWarningSwal('The DNI already exists');
            }
            if (data.status === 200) {
              if (this.$route.params.id) {
                this.showSuccessSwal();
              } else {
                this.showSuccessSwal();
              }
              // this.$router.push({ path: `/${this.routeModule}` });
              this.close();
            }
            this.removePreloader();
            if (this.wasDeleted) this.userData.newarr.push(this.deletedItem);
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You must fill in the necessary information',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
        throw e;
      }
    },
  },
};
</script>
