<template>
  <b-modal
    ref="modal-relate-user"
    :title="'Relate User'"
    @hidden="$emit('hidden')"
    ok-title="Relate"
    @ok.prevent="onSubmit"
    scrollable
    size="lg"
    :hide-footer="!isRelate"
  >
    <template v-if="isRelate">
      <b-row>
        <b-col cols="12" md="6">
          <b-input-group>
            <b-form-input
              placeholder="User"
              v-model="userNameToSearch"
              @keyup.enter="searchUserRrhh"
            />
            <b-input-group-append
              is-text
              class="cursor-pointer"
              @click="searchUserRrhh"
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12"> </b-col>
        <!-- <b-col cols="12" md="6"> </b-col> -->
      </b-row>
      <b-table :fields="visibleFields" :items="users" class="mt-1">
        <template #cell(radio)="data">
          <b-form-radio
            :value="data.item.id"
            v-model="radioUser"
            :id="data.index"
          />
        </template>
        <template #cell(status)="data">
          <b-badge
            size="sm"
            :variant="data.item.status == 1 ? 'success' : 'danger'"
            >{{ data.item.status == 1 ? "Active" : "Inactive" }}</b-badge
          >
        </template>
      </b-table>
    </template>
    <template v-else>
      <b-table :fields="visibleFields" :items="users">
        <template #cell(status)="data">
          <b-badge
            size="sm"
            :variant="data.item.status == 1 ? 'success' : 'danger'"
            >{{ data.item.status == 1 ? "Active" : "Inactive" }}</b-badge
          >
        </template>
        <template #cell(actions)>
          <!-- <b-button variant="warning" size="sm" class="mr-1">Edit</b-button> -->
          <b-button variant="danger" size="sm" @click="deleteUserRelation"
            >Delete</b-button
          >
        </template>
      </b-table>
    </template>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import employeesService from "../../../../employees.service";
export default {
  mixins: [modalMixin],
  props: {
    username: {
      type: Number,
      required: true,
    },
    isRelate: {
      type: Boolean,
      required: true,
    },
    employeeId: {
      type: String,
    },
  },
  mounted() {
    this.toggleModal("modal-relate-user");
    this.userNameToSearch = this.username;
    this.searchUserRrhh();
  },
  data() {
    return {
      users: [],
      fields: [
        {
          key: "radio",
          label: " ",
          visible: this.isRelate ? true : false,
        },
        {
          key: "username",
          label: "User",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          visible: true,
        },
        {
          key: "email",
          label: "Email",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          visible: !this.isRelate ? true : false,
        },
      ],
      userNameToSearch: null,
      radioUser: null,
    };
  },
  methods: {
    async searchUserRrhh() {
      const params = {
        username: this.userNameToSearch,
        isRelate: !this.isRelate,
      };
      const res = await employeesService.searchUserRrhh(params);
      this.users = res.data;
    },
    onSubmit() {
      if (this.isRelate) {
        this.insertRelationEmployee();
      }
    },
    async insertRelationEmployee() {
      if (this.radioUser) {
        const result = await this.showConfirmSwal();
        if (result.value) {
          try {
            this.addPreloader();
            const params = {
              user_id: this.radioUser,
              id_employee: this.employeeId,
            };
            await employeesService.insertRelationEmployee(params);
            this.showSuccessSwal();
            this.$emit("on-insert-relation");
            this.removePreloader();
          } catch (error) {
            this.showErrorSwal(error);
            this.removePreloader();
          }
        }
      }
    },
    async deleteUserRelation() {
      const result = await this.showConfirmSwal();
      if (result.value) {
        try {
          await employeesService.deleteUserRelation({
            employee_id: this.employeeId,
          });
          this.showSuccessSwal();
          this.$emit("on-delete-relation");
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
};
</script>

<style>
</style>